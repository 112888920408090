<template>
  <div class="guesthouses">
    <v-row class="ma-0 pa-0">
      <v-col
        cols="12"
        :md="paramID ? 6 : 12"
        v-if="!$vuetify.breakpoint.mobile || !paramID"
      >
        <list-items
          :listItems="listItems"
          @paginationHandler="fetchList"
          :total="total"
          :loading="loading"
          @searchGuesthouse="searchGuesthouse"
          @reloadData="reloadData"
          @addGuesthouse="addGuesthouse"
          :md="paramID ? 6 : 3"
          @itemClicked="itemClicked"
        ></list-items>
      </v-col>
      <v-col cols="12" :md="6">
        <item-body
          :key="reloadItem"
          v-if="item && item.data && item.data._id"
          :item="item"
          @editGuesthouse="editGuesthouse"
          @reloadData="reloadData"
          @collapse="collapse"
        />
      </v-col>
    </v-row>
    <v-dialog v-model="addGuesthouseDialog" max-width="750px">
      <new-guesthouse-dialog
        :key="addGuesthouseDialog"
        @close="addGuesthouseDialog = false"
        @reloadData="reloadData"
      ></new-guesthouse-dialog>
    </v-dialog>
    <v-dialog v-model="editGuesthouseDialog" max-width="750px">
      <new-guesthouse-dialog
        v-if="paramID"
        :key="editGuesthouseDialog"
        @close="editGuesthouseDialog = false"
        @reloadData="reloadData"
        :itemID="paramID"
      ></new-guesthouse-dialog>
    </v-dialog>
  </div>
</template>

<script>
import ListItems from "@/components/guesthouses/ListItems";
import ItemBody from "@/components/guesthouses/ItemBody";
import NewGuesthouseDialog from "@/components/dialogs/Guesthouse/Index.vue";
export default {
  components: {
    ListItems,
    ItemBody,
    NewGuesthouseDialog
  },
  data: () => ({
    page: 1,
    paramItem: { newPricing: {} },
    paramID: "",
    listItems: [],
    total: 0,
    item: { data: {} },
    loading: true,
    searchWord: undefined,
    editGuesthouseDialog: false,
    addGuesthouseDialog: false,
    reloadItem: false
  }),
  watch: {
    "$route.params": {
      async handler() {
        this.paramID = this.$route.params.id;
        await this.fetchItemByID();
      }
    }
  },
  methods: {
    async reloadData() {
      await this.fetchList(this.page);
      this.fetchItemByID();
    },
    addGuesthouse() {
      this.addGuesthouseDialog = true;
    },
    editGuesthouse() {
      this.editGuesthouseDialog = true;
    },
    async searchGuesthouse(searchWord) {
      this.listItems = [];
      this.page = 1;
      this.searchWord = searchWord;
      await this.fetchList();
      if (!this.$vuetify.breakpoint.mobile) {
        if (!searchWord || searchWord.length == 0) {
          this.paramID = this.$route.params.id;
          this.fetchItemByID();
        } else if (this.listItems.length > 0) {
          this.paramID = this.listItems[0]._id;
          this.fetchItemByID();
        } else {
          this.paramID = undefined;
          this.item = undefined;
        }
      }
    },
    async fetchList(page) {
      this.loading = true;
      if (this.source) {
        this.source.cancel();
      }
      this.source = this.$axios.CancelToken.source();
      const currentSource = this.source;
      if (!page) {
        page = 1;
      }
      this.page = page;
      let { data } = await this.$axios.get(
        `guest-house?${
          (this.searchWord && this.searchWord.length) > 0
            ? "searchWord=" + this.searchWord + "&"
            : ""
        }pageSize=12&pageNumber=${page}&sort=2`,
        { cancelToken: this.source.token }
      );

      if (data) {
        this.listItems = data.data;
        this.total = data.count;
      }
      if (currentSource === this.source) {
        this.loading = false;
      }
    },
    async fetchItemByID() {
      if (this.paramID) {
        let { data } = await this.$axios.get(
          `guest-house/attraction/${this.paramID}`
        );

        if (data) {
          this.item = data;
          this.reloadItem = !this.reloadItem;
        }
      }
    },
    collapse() {
      this.$router.push("/guesthouses");
      this.paramID = undefined;
    },
    itemClicked(item) {
      this.$router.push(`/guesthouses/${item._id}`);
    }
  },
  async created() {
    this.paramID = this.$route.params.id;
    await this.fetchList();
    await this.fetchItemByID();
  }
};
</script>

<style lang="scss" scoped>
.guesthouses {
  background: white;
  height: 100vh;
  overflow: hidden;
}
</style>
