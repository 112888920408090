<template>
  <div class="white pa-5 py-3" :key="updatedKey">
    <main-section
      v-show="step == 1"
      @backHandler="$emit('close')"
      @nextHandler="stepOneHandler"
      :objItem="objItem"
    ></main-section>
    <more-details
      v-if="step == 2"
      @backHandler="backHandler"
      @nextHandler="submitHandler"
      :objItem="objItem"
    ></more-details>
  </div>
</template>

<script>
import MainSection from "./components/Main";
import MoreDetails from "./components/MoreDetails";
export default {
  components: {
    MainSection,
    MoreDetails
  },
  props: {
    itemID: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    step: 0,
    obj: { },
    objItem: {},
    updatedKey: false
  }),
  methods: {
    backHandler(val) {
      if (!this.objItem._id) {
        this.objItem.rooms = val;
      }
      this.step = 1;
    },
    stepOneHandler(val) {
      this.step = 2;
      this.obj = { ...val.obj };
    },
    async submitHandler(val) {
      this.step = 1;
      var isError = false;
      this.obj.differentPrice = true;
      this.obj.numberOfRooms = val.rooms.length;
      if (!this.itemID) {
        let res = await this.$axios.post(`guest-house`, this.obj);
        if (res.data && res.data.success) {
          if (res.data) {
            val.rooms.forEach(async (room) => {
              let formData = new FormData();
              formData.append("attraction", res.data.data.attraction);
              formData.append("weekendPrice", room.weekendPrice);
              formData.append("weekdayPrice", room.weekdayPrice);
              formData.append(
                "discountedWeekendPrice",
                room.discountedWeekendPrice
              );
              formData.append(
                "discountedWeekdayPrice",
                room.discountedWeekdayPrice
              );
              formData.append("numberOfPeople", room.numberOfPeople);
              formData.append("description", JSON.stringify(room.description));
              room.images.forEach((img) => {
                formData.append(`images`, img.file);
              });
              let resRoom = await this.$axios.post(`room`, formData);
              if (!resRoom.data) {
                isError = true;
                this.$store.dispatch("showSnack", {
                  text: "Something went wrong",
                  color: "error"
                });
                return;
              }
            });
            if (!isError) {
              this.$store.dispatch("showSnack", {
                text: "Created Successfully",
                color: `success`
              });
              this.$emit("close");
              this.$emit("reloadData");
            }
          } else {
            this.$store.dispatch("showSnack", {
              text: "Guest house already exists",
              color: "error"
            });
          }
        } else {
          this.$store.dispatch("showSnack", {
            text: "Guest house already exists",
            color: "error"
          });
        }
      } else {
        let res = await this.$axios.put(
          `guest-house/attraction/${this.itemID}`,
          this.obj
        );
        if (res.data) {
          val.deleteRooms.forEach(async (room) => {
            await this.$axios.delete(`room/` + room._id);
          });

          val.deletedRoomImages.forEach(async (img) => {
            await this.$axios.delete(`room/image/${img.roomId}/${img.imageId}`);
          });
          val.rooms.forEach(async (room) => {
            let formData = new FormData();
            formData.append("attraction", res.data.data.attraction._id);
            formData.append("weekendPrice", room.weekendPrice);
            formData.append("weekdayPrice", room.weekdayPrice);
            formData.append(
              "discountedWeekendPrice",
              room.discountedWeekendPrice
            );
            formData.append(
              "discountedWeekdayPrice",
              room.discountedWeekdayPrice
            );
            formData.append("numberOfPeople", room.numberOfPeople);
            formData.append("description", JSON.stringify(room.description));
            room.images.forEach((img) => {
              if (img._id) {
                formData.append(`images`, img._id);
              } else {
                formData.append(`images`, img.file);
              }
            });
            let resRoom;
            if (room._id) {
              resRoom = await this.$axios.put(`room/` + room._id, formData);
            } else {
              resRoom = await this.$axios.post(`room`, formData);
            }

            if (!resRoom.data) {
              isError = true;
              this.$store.dispatch("showSnack", {
                text: "Something went wrong",
                color: "error"
              });
              return;
            }
          });
          if (!isError) {
            this.$store.dispatch("showSnack", {
              text: "Created Successfully",
              color: `success`
            });
            this.$emit("close");
            this.$emit("reloadData");
          }
        } else {
          this.$store.dispatch("showSnack", {
            text: "Guesthouse already exists",
            color: "error"
          });
        }
      }
    },
    async fetchItemByID() {
      let { data } = await this.$axios.get(
        `guest-house/attraction/${this.itemID}`
      );

      if (data) {
        this.objItem = data.data;
        this.updatedKey = !this.updatedKey;
      }
    }
  },
  async created() {
    this.step = 1;
    if (this.itemID) {
      await this.fetchItemByID();
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
