<template>
  <div class="guesthouses">
    <base-select
      v-model="language"
      :items="$root.languages"
      placeholder="Language"
      :returnObject="false"
    ></base-select>
    <span class="font-22 font-500 mt-3 mb-0 d-block"
      >Step 1 of 2: Main details</span
    >

    <v-form v-model="valid" @submit.prevent="submitForm" ref="form">
      <!-- upload image -->
      <div class="mt-5">
        <v-row>
          <!-- **** Main Info **** -->
          <v-col cols="12" md="12" class="mb-0 pt-0">
            <v-row>
              <!-- SELECT GUESTHOUSE -->
              <v-col cols="12" class="py-1 mt-4 relative">
                <label class="grey--text font-12 font-500 mb-1 d-block"
                  >Select Guesthouse</label
                >
                <v-menu
                  class="search-menu"
                  offset-y
                  :content-class="
                    attractionSearch
                      ? 'search-menu-border--active'
                      : 'search-menu-border'
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="search-field mr-3">
                      <v-text-field
                        outlined
                        placeholder="Add a short, clear description"
                        prepend-inner-icon="mdi-magnify"
                        hide-details="true"
                        height="35"
                        dense
                        v-model="attractionSearch"
                        @input="searchAttraction"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </div>
                  </template>
                  <v-list v-if="attractions.length > 0">
                    <v-list-item
                      class="cursor-pointer"
                      @click="selectAttraction(item)"
                      v-for="(item, index) in attractions"
                      :key="index"
                    >
                      <v-list-item-title>{{
                        item.name
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                  <div
                    class="white py-1 px-5"
                    v-if="attractionSearch && attractions.length == 0"
                  >
                    <span>No Results</span>
                  </div>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <!-- line -->
          <v-col cols="12" md="12">
            <div class="b-bottom my-1"></div>
          </v-col>

          <!-- Good To Know -->
          <v-col cols="12" md="12">
            <span class="font-22 font-500 mb-0 d-block">Good to know</span>

            <!-- services -->
            <v-col cols="12" md="12">
              <div class="mt-1" v-for="(type, i) in serviceTypes" :key="i">
                <span class="grey--text font-14 d-block mb-2"
                  >{{ type.title }} Services</span
                >
                <v-row v-for="(service, j) in type.data" :key="j">
                  <!-- delete -->
                  <v-col cols="1">
                    <div class="base-btn" @click="removeOption(j, type.type)">
                      <v-icon color="error">mdi-minus-circle-outline</v-icon>
                    </div>
                  </v-col>

                  <!-- text -->
                  <v-col cols="12" :md="type.type == 'extra' ? 5 : 11">
                    <base-input
                      placeholder="Add a short headline"
                      :rules="[]"
                      v-model="service.description[language]"
                    ></base-input>
                  </v-col>
                  <v-col cols="12" md="3" v-if="type.type == 'extra'">
                    <base-select
                      :items="pricePer"
                      v-model="service.priceType"
                      itemText="name"
                      itemValue="_id"
                      placeholder="Pricing per"
                      :rules="[(v) => !!v || v >= 0 || '']"
                    ></base-select
                  ></v-col>
                  <v-col cols="12" md="3" v-if="type.type == 'extra'">
                    <base-input
                      placeholder="Price"
                      type="number"
                      v-model="service.price"
                      :rules="[(v) => !!v || v >= 0 || '']"
                    ></base-input
                  ></v-col>
                </v-row>

                <!-- add new -->
                <div class="py-1 mt-2">
                  <div class="base-btn" @click="addOption(type.type)">
                    <v-icon color="grey">mdi-plus-circle-outline</v-icon>
                  </div>
                </div>
              </div>
            </v-col>

            <!-- line -->
            <v-col cols="12" md="12" class="px-2 mx-0">
              <div class="b-bottom my-1"></div>
            </v-col>

            <!-- what to tabs -->
            <v-col cols="12" class="pt-0">
              <div class="mt-4" v-for="(whatTo, i) in goodToKnow" :key="i">
                <span class="grey--text font-14 d-block mb-3">{{
                  whatTo.title
                }}</span>
                <v-row v-for="(desc, j) in whatTo.desc" :key="j">
                  <!-- delete -->
                  <v-col cols="1" class="py-1">
                    <div class="base-btn" @click="removeWhatToHandler(i, j)">
                      <v-icon color="error">mdi-minus-circle-outline</v-icon>
                    </div>
                  </v-col>

                  <!-- text -->
                  <v-col cols="11" class="py-1">
                    <base-input
                      placeholder="Add a short headline"
                      :rules="[]"
                      v-model="desc.value[language]"
                    ></base-input>
                  </v-col>
                </v-row>

                <!-- add new -->
                <div class="py-1 mt-2">
                  <div class="base-btn" @click="addWhatToHandler(i)">
                    <v-icon color="grey">mdi-plus-circle-outline</v-icon>
                  </div>
                </div>
              </div>
            </v-col>
          </v-col>

          <v-col cols="12" class="mt-14">
            <!-- buttons -->
            <buttons-experience
              @cancel="$emit('backHandler')"
              @next="handleNext"
            ></buttons-experience>
          </v-col>
        </v-row>
      </div>
    </v-form>
  </div>
</template>

<script>
import ButtonsExperience from "@/components/Buttons/ButtonsExperience.vue";
export default {
  props: {
    objItem: {
      type: Object,
      default: undefined
    },
    step: {
      type: Number,
      default: 0
    }
  },
  components: {
    ButtonsExperience
  },
  data: () => ({
    countries: [{ name: "Lebanon", _id: "LB" }],
    valid: false,
    obj: {
      options: [{}]
    },
    attractions: [],

    goodToKnow: [
      { title: "What To Bring", desc: [{ value: {} }] },
      { title: "What To Wear", desc: [{ value: {} }] },
      { title: "What To Expect", desc: [{ value: {} }] }
    ],
    serviceTypes: [
      { title: "Included", type: "included", data: [{ description: {} }] },
      {
        title: "Extra",
        type: "extra",
        data: [{ description: {}, price: "", priceType: "" }]
      }
    ],
    pricePer: [
      { name: "Person", _id: 1 },
      { name: "Group", _id: 0 }
    ],
    attractionSearch: "",
    language: "en"
  }),
  methods: {
    addWhatToHandler(i) {
      this.goodToKnow[i].desc.push({value: {}});
    },
    removeWhatToHandler(i, j) {
      this.goodToKnow[i].desc = [
        ...this.goodToKnow[i].desc.filter((_, idx) => idx != j)
      ];
    },
    addOption(type) {
      if (type == "included") {
        this.serviceTypes[0].data.push({ description: "" });
      } else {
        this.serviceTypes[1].data.push({
          description: "",
          price: "",
          pricingBer: ""
        });
      }
    },
    removeOption(j, type) {
      if (type == "included") {
        this.serviceTypes[0].data = this.serviceTypes[0].data.filter(
          (_, idx) => idx != j
        );
      } else {
        this.serviceTypes[1].data = this.serviceTypes[1].data.filter(
          (_, idx) => idx != j
        );
      }
    },

    // async fetchItemByID() {
    //   this.obj = { ...this.objItem };

    //   // this.obj = this.objItem;
    //   this.goodToKnow[0].desc = this.objItem.bring.map((val) => {
    //     return { value: val.label, _id: val._d };
    //   });
    //   this.goodToKnow[1].desc = this.objItem.wear.map((val) => {
    //     return { value: val.label, _id: val._d };
    //   });
    //   this.goodToKnow[2].desc = this.objItem.expect.map((val) => {
    //     return { value: val.label, _id: val._d };
    //   });
    // },
    handleNext() {
      this.$refs.form.validate();
      if (!this.valid) {
        setTimeout(() => {
          document
            .querySelector(".v-input.error--text:first-of-type")
            .scrollIntoView({
              behavior: "smooth"
            });
        }, 100);
        return;
      }

      this.obj.options = [];

      this.serviceTypes.forEach((type) => {
        if (type.type == "included") {
          this.serviceTypes[0].data.forEach((option) => {
            this.obj.options.push({
              description: option.description,
              included: true
            });
          });
        } else {
          this.serviceTypes[1].data.forEach((option) => {
            this.obj.options.push({
              description: option.description,
              price: +option.price,
              priceType: option.priceType._id
                ? +option.priceType._id
                : option.priceType
            });
          });
        }
      });

      this.obj.options = this.obj.options.filter(
        (option) => option.description
      );

      this.obj.bring = this.goodToKnow[0].desc.map((val) => {
        return { label: val.value };
      });
      this.obj.wear = this.goodToKnow[1].desc.map((val) => {
        return { label: val.value };
      });
      this.obj.expect = this.goodToKnow[2].desc.map((val) => {
        return { label: val.value };
      });
      this.$emit("nextHandler", {
        obj: this.obj
      });
    },
    async searchAttraction() {
      this.attractionUpdated = !this.attractionUpdated;
      let { data } = await this.$axios.get(
        `miniguide/all/new?${
          (this.attractionSearch && this.attractionSearch.length) > 0
            ? "searchWord=" + this.attractionSearch
            : ""
        }`
      );
      if (data) {
        this.attractions = data.data;
      }
    },
    async fetchAttractions() {
      let { data } = await this.$axios.get(
        "attraction/all/dropdown?pageNumber=1&pageSize=20"
      );
      if (data) {
        this.attractions = data.data;
      }
    },
    selectAttraction(attration) {
      this.obj.attraction = attration._id;
      this.attractionSearch = attration.name || attration.title;
    }
  },
  async created() {
    this.language = this.$root.language;
    await this.searchAttraction();
    if (this.objItem && this.objItem._id) {
      this.obj = this.objItem;

      if (this.obj.attraction) {
        this.attractionSearch = this.obj.attraction.name[this.$root.language];
      }
      // bring
      this.goodToKnow[0].desc = this.obj.bring?.map((val) => {
        return { value: val.label, _id: val._id };
      });
      // wear
      this.goodToKnow[1].desc = this.obj.wear?.map((val) => {
        return { value: val.label, _id: val._id };
      });
      // expect
      this.goodToKnow[2].desc = this.obj.expect?.map((val) => {
        return { value: val.label, _id: val._id };
      });

      this.serviceTypes[0].data = this.objItem.options?.filter(
        (option) => option.included
      );
      this.serviceTypes[1].data = this.objItem.options?.filter(
        (option) => !option.included
      );
      this.obj.options = [{}];
    }
  }
};
</script>

<style lang="scss" scoped>
.guesthouses {
  .v-input {
    label {
      font-size: 14px !important;
      font-weight: 400;
    }
  }
}
</style>
