<template>
  <div class="details-steps">
    <v-tabs v-model="selectedRoomIndex" centered class="mb-5">
      <v-tabs-slider color="secondary"></v-tabs-slider>

      <v-tab
        v-for="(room, i) in item.data.rooms"
        :key="i"
        @click="selectRoomHandler(room)"
      >
        Room {{ room.roomNumber || i + 1 }}
      </v-tab>
    </v-tabs>
    <div class="steps" v-if="selectedRoom">
      <div class="mb-8">
        <div class="w-100">
          <div class="main-title">
            <p class="font-16" style="white-space: pre-line">
              {{ selectedRoom.description ? selectedRoom[$root.language] : '' }}
            </p>
            <div class="d-flex">
              <p class="font-16 font-500">Weekday Price:</p>
              <p class="old-price font-18 ml-2">
                {{ selectedRoom.weekdayPrice }}
              </p>
              <p class="font-18 ml-2">
                {{ selectedRoom.discountedWeekdayPrice }}
              </p>
              </div>
              <div class="d-flex">
              <p class="font-16 font-500">Weekend Price:</p>
              <p class="old-price font-18 ml-2">
                {{ selectedRoom.weekendPrice }}
              </p>
              <p class="font-18 ml-2">
                {{ selectedRoom.discountedWeekendPrice }}
              </p>
            </div>
          </div>
          <div class="photos-section">
            <div
              class="photo mb-2 ml-5 cursor-pointer"
              v-for="(image, i) in selectedRoom.images"
              :key="i"
              style="height: 120px; width: 100px; float: left"
            >
              <img
                :src="image.url"
                @click="photosDialogHandler([image])"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="photosDialog"
      fullscreen
      hide-overlay
      transition="scale-transition"
      content-class="full-dialog"
    >
      <dialog-photos
        :key="photosDialog"
        :images="images"
        @close="photosDialog = false"
      ></dialog-photos>
    </v-dialog>
  </div>
</template>

<script>
import DialogPhotos from "@/components/dialogs/Photos";

export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    DialogPhotos
  },
  data: () => ({
    photosDialog: false,
    images: [],
    selectedRoomIndex: "0",
    selectedRoomObject: undefined
  }),
  computed: {
    selectedRoom() {
      return this.selectedRoomObject || this.item.data.rooms[0];
    }
  },
  methods: {
    selectRoomHandler(room) {
      this.selectedRoomObject = room;
    },
    photosDialogHandler(images) {
      this.photosDialog = true;
      this.images = images;
    }
  }
};
</script>

<style lang="scss">
.details-steps {
  .steps {
    position: relative;

    .old-price {
      text-decoration: line-through;
    }
  }
}
</style>
