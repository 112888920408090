<template>
  <div>
    <base-select
      v-model="language"
      :items="$root.languages"
      placeholder="Language"
      :returnObject="false"
    ></base-select>
    <span class="font-22 font-500 mt-3 mb-0 d-block"
      >Step 2 of 2: More details</span
    >
    <!-- Line -->
    <div class="b-bottom mt-3 mb-4"></div>
    <v-form v-model="valid" @submit.prevent="submitForm" ref="form">
      <div v-for="(room, i) in rooms" :key="i">
        <span class="font-22 font-500 d-block mb-4">Room {{ i + 1 }}</span>
        <v-row>
          <v-col cols="12" md="4">
            <label class="grey--text font-14 font-500 mb-1 d-block"
              >Number of people</label
            >
            <base-input
              v-model="room.numberOfPeople"
              type="number"
              placeholder="Number"
              :rules="[]"
            >
            </base-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <label class="grey--text font-14 font-500 mb-1 d-block"
              >WEEKDAY PRICE</label
            >
            <base-input
              v-model="room.weekdayPrice"
              type="number"
              placeholder="Number"
              :rules="[]"
            >
            </base-input>
          </v-col>

          <v-col cols="12" md="6">
            <label class="grey--text font-14 font-500 mb-1 d-block"
              >DISCOUNT WEEKDAY PRICE</label
            >
            <base-input
              v-model="room.discountedWeekdayPrice"
              type="number"
              placeholder="Number"
              :rules="[]"
            >
            </base-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <label class="grey--text font-14 font-500 mb-1 d-block"
              >WEEKEND PRICE</label
            >
            <base-input
              v-model="room.weekendPrice"
              type="number"
              placeholder="Number"
              :rules="[]"
            >
            </base-input>
          </v-col>
          <v-col cols="12" md="6">
            <label class="grey--text font-14 font-500 mb-1 d-block"
              >DISCOUNT WEEKEND PRICE</label
            >
            <base-input
              v-model="room.discountedWeekendPrice"
              type="number"
              placeholder="Number"
              :rules="[]"
            >
            </base-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <label class="grey--text font-14 font-500 mb-1 d-block"
              >DESCRIPTION</label
            >
            <base-textarea
              v-model="room.description[language]"
              placeholder="Description"
            >
            </base-textarea>
          </v-col>

          <!-- **** Images & Media **** -->
          <v-col cols="12" class="mb-0 pt-0" :key="uploadedFile">
            <span class="font-26 font-500 d-block mb-5">Images & Media</span>
            <v-row class="media">
              <v-col cols="6" md="3" v-for="(image, j) in room.images" :key="j">
                <custom-image
                  @delete="deleteHandler(i, j, room, image)"
                  :image="image.url"
                ></custom-image>
              </v-col>
            </v-row>
          </v-col>

          <!-- upload new image -->
          <v-col cols="12">
            <div class="upload-item px-6 py-3 mb-2">
              <div>
                <v-icon color="grayicon">mdi-folder-multiple-image</v-icon>
                <span class="font-14 black--text d-inline-block ml-2"
                  >Upload Image</span
                >
              </div>
              <v-file-input
                :key="uploadedFile"
                prepend-icon=""
                @change="handleUpload($event, i)"
                height="50"
                hide-details="true"
                accept="image/*"
                multiple
              ></v-file-input>
            </div>
          </v-col>

          <v-col cols="12" class="mb-1" v-if="rooms.length > 1">
            <div class="cursor-pointer" @click="deleteRoom(i)">
              <v-icon color="error">mdi-delete</v-icon>
              <span class="error--text">Delete this room</span>
            </div>
          </v-col>
          <!-- Line -->
          <v-col cols="12">
            <div class="b-bottom mb-2"></div>
          </v-col>
        </v-row>
      </div>
      <!-- Add new room -->
      <v-col cols="12">
        <div class="add-new-room d-flex py-3" @click="addRoom">
          <div
            class="secondary align-center d-flex justify-center mx-2 d-block"
            style="width: 30px; height: 30px; border-radius: 50%"
          >
            <span class="white--text font-13 font-500"
              ><v-icon color="white" size="18">mdi-plus</v-icon></span
            >
          </div>
          <p class="d-inline-block ml-2 my-1">Add new room</p>
        </div>
      </v-col>
    </v-form>

    <buttons-experience
      @cancel="backHandler"
      @next="handleNext"
      cancelText="Back"
      text="Submit"
    ></buttons-experience>
  </div>
</template>

<script>
import CustomImage from "@/components/dialogs/Experience/components/main/CustomImage.vue";
import ButtonsExperience from "@/components/Buttons/ButtonsExperience.vue";

export default {
  props: {
    objItem: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    ButtonsExperience,
    CustomImage
  },
  data: () => ({
    rooms: [],
    deleteRooms: [],
    deletedRoomImages: [],
    images: [],
    valid: false,
    uploadedFile: false,
    language: "en"
  }),
  methods: {
    backHandler() {
      this.$emit("backHandler", this.rooms);
    },
    handleUpload(files, idx) {
      files.forEach((file) => {
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 1) {
          this.$store.dispatch("showSnack", {
            text: "File size too big! bigger than 1MB, select a smaller one",
            color: "error"
          });
          return;
        }
        if (this.rooms[idx].images.length >= 10) {
          this.$store.dispatch("showSnack", {
            text: "max images for single room is 10",
            color: "error"
          });
          return;
        }
        this.rooms[idx].images.push({
          file: file,
          url: URL.createObjectURL(file)
        });
      });
      this.uploadedFile = !this.uploadedFile;
    },
    deleteHandler(roomId, imageId, room, img) {
      this.rooms[roomId].images = [
        ...this.rooms[roomId].images.filter((_, idx) => idx != imageId)
      ];
      if (this.$route.params.id) {
        this.deletedRoomImages.push({ roomId: room._id, imageId: img._id });
      }
      this.uploadedFile = !this.uploadedFile;
    },
    handleNext() {
      this.$refs.form.validate();
      if (!this.valid) {
        setTimeout(() => {
          document
            .querySelector(".v-input.error--text:first-of-type")
            .scrollIntoView({
              behavior: "smooth"
            });
        }, 100);
        return;
      }

      if (this.equalPrice) {
        let newRooms = [];
        for (let index = 0; index < this.selectedRoom; index++) {
          newRooms.push(this.rooms[index]);
        }
        this.rooms = newRooms.filter((room) => room != undefined);
      }

      this.$emit("nextHandler", {
        rooms: [...this.rooms],
        deleteRooms: [...this.deleteRooms],
        deletedRoomImages: [...this.deletedRoomImages]
      });
    },
    deleteRoom(idx) {
      this.deleteRooms.push(this.rooms[idx]);
      this.rooms = this.rooms.filter((_, i) => i != idx);
    },
    fetchRooms() {
      if (this.objItem && this.objItem._id) {
        this.rooms = this.objItem.rooms || [];
      }
    },
    addRoom() {
      this.rooms.push({ description: {}, images: [] });
    }
  },
  created() {
    this.language = this.$root.language;
    this.rooms = [];
    this.deleteRooms = [];
    this.fetchRooms();
  }
};
</script>

<style>
.media {
  max-height: 250px;
  overflow: auto;
}
.add-new-room {
  border: 1px solid gray;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
}
</style>
