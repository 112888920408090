<template>
  <div class="details-text">
    <div class="b-bottom pt-4">
      <!-- text details -->
      <div class="main-title d-flex align-center justify-space-between">
        <div class="main-title">
          <p
            class="my-2 black--text transition-3s font-20 font-500"
            style="float: left"
          >
            {{ item.attraction.name[$root.language] }}
          </p>
          <p
            @click="goToAttraction"
            class="my-3 ml-2 blue--text transition-3s font-16 cursor-pointer"
            style="float: left"
          >
            Go to Attraction
          </p>
        </div>
        <div class="d-flex" v-if="!viewOnly">
          <div
            v-if="actions.includes('delete')"
            class="icon-cover mr-1 cursor-pointer base-btn"
            @click="deleteConfirm"
          >
            <v-icon color="error">mdi-delete</v-icon>
          </div>
          <div
            v-if="actions.includes('edit')"
            class="icon-cover cursor-pointer base-btn"
            @click="$emit('editHandler')"
          >
            <v-icon color="black-grey">mdi-pencil</v-icon>
          </div>
          <div
            v-if="actions.includes('restore')"
            class="icon-cover mr-1 cursor-pointer base-btn"
            @click="$emit('restoreHandler', item)"
          >
            <v-icon color="green">mdi-file-undo</v-icon>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <p class="main-title d-block font-12 font-300">Included Services:</p>
        <div
          class="ml-2 black-grey--text transition-3s font-12"
          v-for="(item, i) in item.options"
          :key="i"
          :class="{
            'green--text': item.included,
            'is-not-selected': !item.included
          }"
        >
          {{ item.description[$root.language]
          }}<span class="green--text">{{
            item.price
              ? "+" +
                item.price +
                (item.priceType !== undefined
                  ? ` per ${PersonGroupEnum.init(0)}`
                  : "")
              : ""
          }}</span>
        </div>
      </div>
    </div>
    <v-dialog v-model="deleteDialog" width="300">
      <delete-confirm
        :key="deleteDialog"
        @confirmAccess="deleteHandler"
        @closeDialog="deleteDialog = false"
      ></delete-confirm>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    viewOnly: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Array,
      default: () => ["delete", "edit"]
    }
  },
  data: () => ({
    deleteDialog: false,
    experienceDelete: { Experiences: "delete" },
    experienceEdit: { Experiences: "edit" },
    deleteEnable: false,
    editEnable: false,
    PersonGroupEnum: {
      Person: "Person",
      Group: "Group",
      init: function (integer) {
        if (integer == 0) {
          return this.Group;
        } else {
          return this.Person;
        }
      }
    }
  }),
  methods: {
    goToAttraction() {
      this.$router.push(`/attractions/${this.item.attraction._id}`);
    },
    deleteConfirm() {
      this.deleteDialog = true;
    },
    async deleteHandler() {
      const res = await this.$axios.delete(
        "guest-house/attraction/" + this.item.attraction._id
      );

      if (res && res.data) {
        this.$store.dispatch("showSnack", {
          text: "deleted successfully",
          color: "success"
        });
        this.deleteDialog = false;
        this.$emit("reloadData");
        this.$emit("collapse");
      } else {
        this.$store.dispatch("showSnack", {
          text: res.error,
          color: "error"
        });
      }
    }
  },
  async created() {
    this.deleteEnable = await this.$store.dispatch(
      "checkPermission",
      this.experienceDelete
    );
    this.editEnable = await this.$store.dispatch(
      "checkPermission",
      this.experienceEdit
    );
  }
};
</script>

<style scoped>
.main-title {
  overflow: hidden;
}
</style>
